<template>
  <div>
    <div>
      <img class="top_image" :src="menu.bannerImg" alt=""/>
    </div>
    <!-- 面包屑 -->
    <div class="mbx">
      <div style="width: 1200px; margin: 20px auto">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            <router-link to="/">首页</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 16px">
            <router-link :to="{ path: '/supply', query: { id: supplyId } }">货源信息</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 16px">
            货源详情
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <p class="small_line"></p>
    </div>
    <div class="fgx">
      <p class="title"><span>货源详情</span></p>
      <div class="line">
        <p class="linebg"></p>
      </div>
    </div>

    <div class="detal">
      <el-form label-width="120px">
<!--        <el-row>-->
<!--          <el-col>-->
<!--            <el-form-item label="原始单号">-->
<!--              {{ supply.originalNo }}-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
        <el-row>
          <el-col :span="12">
            <el-form-item label="委托编号">
              {{ supply.supplySn }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发车数量">
              {{ supply.transTotal }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货开始时间">
              {{ supply.sendTimeStart }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发货结束时间">
              {{ supply.sendTimeEnd }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="始发城市">
              {{ supply.startCity }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="始发城市地址">
              {{ supply.startAddress }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="目的城市">
              {{ supply.endCity }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="目的城市地址">
              {{ supply.endAddress }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="行驶里程(公里)">
              {{ supply.distance }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预计时间(分钟)">
              {{ supply.duration }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="货物名称">
              {{ supply.goodsName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="货物类别">
              {{ supply.goodsType }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="货物重量(KG)">
              {{ supply.goodsWeight }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车辆类型">
              {{ supply.carType }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="按照吨位计费">
              {{ supply.isCalbyton == 0 ? '否' : '是' }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="运输单价">
              {{ supply.transUnitPrice }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="运费金额">
              {{ supply.totalAmount }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="预付油卡金额">
              {{ supply.prepayCardAmount }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预付款">
              {{ supply.prepayAmoun }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="回单押金">
              {{ supply.returnDeposit }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合理损耗(KG)">
              {{ supply.rationalLoss }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货人(公司)">
              {{ supply.fhrCompany }}
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="发货人姓名">
              {{ supply.fhrName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发货人电话">
              {{ supply.fhrPhone }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="收货人(公司)">
              {{ supply.fhrName }}
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="收货人姓名">
              {{ supply.fhrName }}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="收货人电话">
              {{ supply.fhrName }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注">
              {{ supply.remark}}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  data() {
    return {
      detal: "",
      supplyId: this.$route.params.id,
      supply: {},
      menu: {
        bannerImg: ""
      }
    };
  },
  created() {
    this.getSupplyDetail();
  },
  methods: {
    getSupplyDetail() {
      axios.get(window.apiUrl + "/api/common/supply/" + this.supplyId).then(res => {
        this.supply = res.data.data;
      });
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-align: center;
}
.el-row{
  border:1px solid #eee;
}
.el-col{
  border-right:1px solid #eee;
}

.small_line {
  background: #ddd;
  height: 1px;
}

.ywx_content_line1 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bews_line {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.top_image {
  width: 100%;
}

.fgx {
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
}

.fgx .title {
  font-weight: 900;
  text-align: left;
  margin-bottom: 10px;
}

.fgx .line {
  width: 100%;
  height: 5px;
  background-color: #eeeeee;
}

.line .linebg {
  height: 5px;
  width: 64px;
  background-color: black;
}

.http {
  width: 700px;
  margin: 0 auto;
  border: 1px solid #dddddd;
  height: 30px;
  margin-bottom: 100px;
  line-height: 30px;
}

.bigline {
  height: 1px;
  width: 1200px;
  margin: 10px auto;
  background-color: #dddddd;
}

.middle .newscontent {
  margin: 0 auto;
  width: 1200px;
  margin-top: 20px;
  vertical-align: top;
  text-align: left;
  margin-bottom: 60px;
  padding-bottom: 30px;
  position: relative;
}

.middle .newscontent .news_content {
  display: inline-block;
  width: 358px;
  height: 240px;
  margin-left: 20px;
  margin-right: 20px;
  border: 1px solid #dddddd;
  margin-top: 20px;
  vertical-align: top;
}

.middle .newscontent .news_content .mdimgcontent {
  width: 358px;
  height: 180px;
  position: relative;
}

.middle .newscontent .news_content .dw {
  margin: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99;
  text-align: left;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.detal {
  width: 1000px;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 40px;
  border: 1px solid #ddd;

}

.middle .middle_title {
  margin-top: 50px;
  font-size: 30px;
  font-weight: 700;
  color: rgb(249, 145, 0);
}

.downbtn {
  position: absolute;
  height: 40px;
  left: 50%;
  transform: translate(-52px);
  bottom: -40px;
}

.jiazaimes {
  font-size: 18px;
  color: rgb(249, 145, 0);
}

.middle .newscontent .news_content:hover {
  box-shadow: 0 0 20px #aaa;
}
</style>
